import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CardActionArea from "@mui/material/CardActionArea";
import Divider from "@mui/material/Divider";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import logo from "../styles/logo.png";

export default function ImageCard({ setIsDetail, l }) {
  console.log("l: ", l);

  let imageSource;
  if (l.images && l.images.length > 0) {
    imageSource = l.images.filter((i) => i.is_main)[0]?.thumb;
  } else {
    imageSource =
      "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png";
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        bgcolor: "background.paper",
        overflow: "hidden",
        borderRadius: "12px",
        boxShadow: 1,
        fontWeight: "bold",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
    >
      <CardActionArea
        component={Link}
        onClick={() => setIsDetail(true)}
        to={`/app/${l.padleads_id}`}
        sx={{ flexGrow: 1, flexDirection: "column", alignItems: "stretch" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              height: 167,
              width: 250,
              maxHeight: { xs: 167, md: 167 },
              minWidth: { xs: 220, md: 220 },
            }}
            alt="Picture."
            src={imageSource}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png";
            }}
          />

          <Box
            id="card-info"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              component="span"
              sx={{ pl: 2, fontSize: { xs: 12, md: 18 }, mt: 1 }}
            >
              {l.city}
            </Box>
            <Box
              component="span"
              sx={{ pl: 2, color: "primary.main", fontSize: 22 }}
            >
              ${l.rent}
            </Box>
            <Divider
              style={{
                position: "relative",
                width: "100%",
                height: 5,
              }}
            />
            <Box
              sx={{
                mt: 1.5,
                ml: 2,
                p: 0.5,
                width: 100,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                borderRadius: "5px",
                color: "primary.main",
                fontWeight: "medium",
                display: "flex",
                justifyContent: "center",
                fontSize: 12,
                alignItems: "center",
                "& svg": {
                  fontSize: 16,
                  mr: 0.5,
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  fontSize: "16px",
                }}
              >
                {l.beds} <BedOutlinedIcon style={{ verticalAlign: "middle" }} />
                {l.baths == "1.00" && "1"}
                {l.baths == "1.50" && "1½"}
                {l.baths == "1.75" && "1¾"}
                {l.baths == "2.00" && "2"}
                {l.baths == "2.50" && "2½"}
                {l.baths == "2.75" && "2¾"}
                {l.baths == "3.00" && "3"}
                {l.baths == "3.50" && "3½"}
                {l.baths == "3.75" && "3¾"}
                {l.baths == "4.00" && "4"}
                {l.baths == "4.50" && "4½"}
                {l.baths == "4.75" && "4¾"}
                {l.baths == "5.00" && "5"}
                {l.baths == "5.50" && "5½"}
                {l.baths == "5.75" && "5¾"}
                <BathtubOutlinedIcon style={{ verticalAlign: "middle" }} />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Box>
  );
}
