import { createRef } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { SnackbarProvider } from 'notistack';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Button from '@mui/material/Button'
import Slide from '@mui/material/Slide';




ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider 
    maxSnack={3}  
    autoHideDuration={3000}
    TransitionComponent={Slide}
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
    }}
    iconVariant={{
      success: '✅',
      error: '❌',
      warning: '⚠️',
      info: '(👍 ͡Ⓘ ω ͡Ⓘ)👍',
  }}

    
    >
        <App />
    </SnackbarProvider>
  </ThemeProvider>,
  document.querySelector('#root'),
);
