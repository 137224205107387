import { useEffect, useState, useContext } from "react";
import api from "../config/client";

import moment from "moment-timezone";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ProfileForm from "../forms/ProfileForm";
import sleep from "../config/sleep";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { UserContext } from "../contexts/UserContext";

function Profile() {
  const {
    isAuth,
    setIsAuth,
    su,
    isSu,
    profile,
    setProfile,
    p_loading,
    setPLoading,
  } = useContext(UserContext);

  console.log("Profile State After: ", profile);

  // useEffect(() => {
  //   if (profile !== {}) {
  //     setIsLoading(false);
  //   }
  //   console.log('profile state after: ', profile);
  // }, [profile]);

  return (
    <Container maxWidth="sm">
      {p_loading ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress />

          <Typography paragraph> Loading </Typography>
        </Stack>
      ) : (
        <ProfileForm
          profile={profile}
          setProfile={setProfile}
          p_loading={p_loading}
          setPLoading={setPLoading}
        />
      )}
    </Container>
  );
}

export default Profile;
