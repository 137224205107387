import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "./App.css";
import api from "./config/client";
// import AuthRouter from './routes/router'
import { UserContext } from "./contexts/UserContext";
import { ListingContext } from "./contexts/ListingContext";
import { useSnackbar } from "notistack";
import useLocalStorage from "./hooks/useLocalStorage";
import InitGA from "./config/ga";

export default function App() {
  const [isAuth, setIsAuth] = useLocalStorage("isAuth", false);
  const [su, setIsSu] = useLocalStorage("su", false);
  const [profile, setProfile] = useLocalStorage("profile", {});

  const [listings, setListings] = useLocalStorage("listings", []);
  const [allListings, setAllListings] = useLocalStorage("allListings", []);

  const [cities, setCities] = useLocalStorage("cities", []);

  const [loading, setLoading] = useState(false);
  const [p_loading, setPLoading] = useLocalStorage("p_loading", false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadListings = async () => {
    try {
      setLoading(true);

      let res = await api.get("/api/listings/");

      if (res.status !== 200) return;
      console.log("Listings Response: ", res);
      setListings(res.data);
      setAllListings(res.data);
      setLoading(false);
    } catch (err) {
      console.log("Listings Error: ", err);
      enqueueSnackbar("Error loading listings", { variant: "error" });
    }
  };

  useEffect(() => {
    InitGA();

    if (isAuth) {
      let token = localStorage.getItem("token");
      api.defaults.headers["Authorization"] = token ? `Token ${token}` : null;
    }

    loadListings();
  }, []);

  useEffect(() => {
    console.log("Listings: ", listings);

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const loadCities = async () => {
      try {
        let rawArray = [];
        for (let i = 0; i < allListings.length; i++) {
          rawArray.push(allListings[i].city);
        }
        let unique = rawArray.filter(onlyUnique);
        console.log("Unique Cities: ", unique);
        let citiesArray = [];
        for (let i = 0; i < rawArray.length; i++) {
          citiesArray.push({
            label: unique[i],
          });
        }
        setCities(unique);
      } catch (err) {
        console.log("Cities Error: ", err);
        enqueueSnackbar("Cities Error", { variant: "error" });
      }
    };

    loadCities();
  }, [allListings]);

  return (
    <ListingContext.Provider value={{ listings, setListings, loading, cities }}>
      <UserContext.Provider
        value={{
          isAuth,
          setIsAuth,
          su,
          setIsSu,
          profile,
          setProfile,
          p_loading,
          setPLoading,
        }}
      >
        <BrowserRouter>
          <Router loading={loading} />
        </BrowserRouter>
      </UserContext.Provider>
    </ListingContext.Provider>
  );
}
