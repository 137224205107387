import axios from "axios";
import csrftoken from "./csrftoken";
import { BASE_URL } from "./constants";
console.log('BASE_URL: ', BASE_URL);
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRFToken": csrftoken,
  },
  withCredentials: false,
});

export default api;

export const successVariant = { variant: "success" };
