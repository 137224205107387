import ReactGA from "react-ga4";

const InitGA = () => {
  try {
    ReactGA.initialize("G-PTLQ5WTYQM");
  } catch (err) {
    console.log("GA error: ", err);
  }
};

export const TrackLogin = () => {
  // Send a custom event
  ReactGA.event({
    category: "Actions",
    action: "Login",
  });
};

export default InitGA;
