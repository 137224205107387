import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Dialog from '@mui/material/Dialog';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel({ images }) {

  console.log("Carousel component render, images:", images);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const maxSteps = images.length;

  useEffect(() => {
    console.log("Carousel images loaded:", images); // Debug: Check the images array on load
  }, [images]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClickOpen = (img) => {
    console.log("Opening dialog with image URL:", img); // Debug: Verify image URL on click
    setOpenDialog(true);
    setSelectedImg(img);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if(openDialog) {
      console.log("Dialog open with selected image URL:", selectedImg); // Debug: Confirm selected image URL when dialog opens
    }
  }, [openDialog, selectedImg]);

  return (
    <Box sx={{ minWidth: { xs: 400, md: "100%" }, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={!openDialog}
      >
        {images.map((step, index) => (
          <div key={index} onClick={() => handleClickOpen(step.original)}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: "auto",
                  display: "block",
                  maxWidth: "100%",
                  overflow: "hidden",
                  cursor: 'pointer',
                }}
                src={step.thumb}
                alt={`Image ${index + 1}`}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        variant="progress"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        onClick={handleClose}
        maxWidth="lg"
        fullWidth
      >
        {selectedImg ? (
          <img src={selectedImg} alt="Enlarged view" style={{ width: '100%', height: 'auto' }} />
        ) : (
          console.log("Dialog attempted to open, but no image URL is selected") // Debug: Confirm dialog logic when no image URL
        )}
      </Dialog>
    </Box>
  );
}

export default Carousel;
