import { useState, useContext, forwardRef } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";
import { OutlinedInput } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import { IMaskInput } from "react-imask";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";

import PropTypes from "prop-types";

const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg", "image/gif"];

const Input = styled("input")({
  display: "none",
});

const Label = styled("label")({
  marginBottom: "10px",
});

const validationSchema = yup.object({
  first_name: yup.string(),
  last_name: yup.string(),
  phone_number: yup
    .string()
    .nullable()
    .min(14, "Phone Number must be 10 digits"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  profile_picture: yup
    .mixed()
    .nullable()
    .test(
      "File Size",
      "upload file",
      (value) => !value || (value && value.size <= 1024 * 1024)
    )
    .test(
      "Format",
      "upload file",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});

const initialValues = (props) => {
  console.log("profileForm Initial Values Props: ", props);

  return {
    first_name: props.profile.user?.first_name,
    last_name: props.profile.user?.last_name,
    phone_number: props.profile?.phone_number,
    email: props.profile.user?.email,
    profile_picture: "",
  };
};

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ProfileForm = (props) => {
  const { profile, setProfile, p_loading, setPLoading } = props;
  console.log("profile: ", profile);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values) => {
    try {
      console.log("Profile Form Values: ", values);
      var formElement = document.querySelector("form");
      let form_data = new FormData(formElement);
      form_data.append("profile_picture", values.profile_picture);
      setPLoading(true);
      let { data, status } = await api.patch("/api/user_endpoint/", form_data);
      if (status !== 200) return;
      setProfile(data);
      setSuccess(true);
      setPLoading(false);

      enqueueSnackbar("Profile Updated", { variant: "success" });
    } catch (err) {
      console.log("Profile Error: ", err);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  return (
    <Container maxWidth="sm">
      <Slide in={!p_loading} direction="down">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Avatar
            alt="Remy Sharp"
            src={props.profile.profile_picture}
            sx={{ width: 56, height: 56 }}
          />
        </Stack>
      </Slide>
      <Formik
        initialValues={initialValues(props)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(formik) => (
          <Form>
            <TextField
              fullWidth
              {...formik.getFieldProps("first_name")}
              id="first_name"
              label="First name"
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
            <TextField
              fullWidth
              {...formik.getFieldProps("last_name")}
              id="last_name"
              label="Last name"
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel
                sx={{ display: "flex", alignSelf: "center", ml: 2 }}
                htmlFor="phone_number"
              >
                Phone Number
              </InputLabel>
              <OutlinedInput
                fullWidth
                {...formik.getFieldProps("phone_number")}
                id="phone_number"
                inputComponent={TextMaskCustom}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
              />
              <FormHelperText error id="accountId-error">
                {formik.touched.phone_number && formik.errors.phone_number}
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              {...formik.getFieldProps("email")}
              id="email"
              disabled
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Box m={2}>
              <Label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(event) => {
                    let file = event.target.files[0];
                    console.log("On Change File: ", file);
                    formik.setFieldValue("profile_picture", file);
                  }}
                />
                <Button variant="contained" component="span">
                  Upload
                </Button>
              </Label>

              <FormHelperText error id="profile_picture_error">
                {formik.touched.profile_picture &&
                  formik.errors.profile_picture}
              </FormHelperText>
            </Box>
            {formik.isValid && (
              <LoadButton
                loading={p_loading}
                success={success}
                disabled={formik.isSubmitting || !formik.isValid}
                text="Send"
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ProfileForm;
