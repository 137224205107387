import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { v4 as uuidv4 } from "uuid";

const Features = ({ features }) => {
  console.log(features);

  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {features.map((pval, pidx) => {
        return (
          <Stack key={uuidv4()} direction="column">
            <Typography variant="h5">
              {capitalizeFirstLowercaseRest(pval[0])}
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {pval[1].map((value, idx) => {
                return (
                  <Box mb={1}>
                    <Chip key={uuidv4()} label={value} />{" "}
                  </Box>
                );
              })}
            </Stack>
            <Divider sx={{ m: 3 }} />
          </Stack>
        );
      })}
    </Box>
  );
};
export default Features;
