import SyncForm from "../forms/SyncForm";
import Container from "@mui/material/Container";

function Sync() {
  return (
    <Container maxWidth="sm">
      <h2>Sync</h2>
      <SyncForm />
    </Container>
  );
}

export default Sync;
