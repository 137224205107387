import { useState, useContext } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { green, blue } from "@mui/material/colors";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { DRAWER_WIDTH } from "../config/constants";
import PlaceIcon from "@mui/icons-material/Place";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ExploreIcon from "@mui/icons-material/Explore";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

import Modal from "@mui/material/Modal";
import ListingForm from "../forms/ListingForm";
import { ListingContext } from "../contexts/ListingContext";
import Tab1 from "../pages/home/Tab1";
import Tab2 from "../pages/home/Tab2";
import Tab3 from "../pages/home/Tab3";
// import MicButton from "../components/Mic";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={value !== 1 ? { p: 2.6 } : { p: 0 }}>{children}</Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: "fixed",
  bottom: 76,
  right: 16,
};

const fabGreenStyle = {
  color: "common.white",
  bgcolor: green[500],
  "&:hover": {
    bgcolor: green[600],
  },
};
const fabBlueStyle = {
  color: "common.white",
  bgcolor: blue[500],
  "&:hover": {
    bgcolor: blue[600],
  },
};

export default function FabTabs(props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setListings, loading, cities } = useContext(ListingContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: "secondary",
      sx: { ...fabStyle, ...fabBlueStyle },
      icon: <FilterAltOutlinedIcon />,
      label: "Filter",
    },
    {
      color: "inherit",
      sx: { ...fabStyle, ...fabGreenStyle },
      icon: <GpsFixedIcon />,
      label: "Expand",
    },
  ];

  return (
    <Box id="fabTabBoxContainer">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <ListingForm
            cities={cities}
            setListings={setListings}
            loading={loading}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          sx={{ backgroundColor: "white" }}
        >
          {/*<MicButton />*/}
          <Tab2 />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Tab3 />
        </TabPanel>
      </SwipeableViews>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          {index == 0 ? (
            <Fab
              onClick={handleOpen}
              sx={fab.sx}
              aria-label={fab.label}
              color={fab.color}
            >
              {fab.icon}
            </Fab>
          ) : (
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          )}
        </Zoom>
      ))}

      <AppBar
        position="fixed"
        color="default"
        sx={{ display: "flex", top: "auto", bottom: 0 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab icon={<ExploreIcon />} label="Rentals" {...a11yProps(0)} />
          <Tab icon={<PlaceIcon />} label="Maps" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
    </Box>
  );
}
