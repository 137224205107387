import { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "../../styles/leafletContainer.css";
import "leaflet/dist/leaflet.css";
import icon from "../../config/icon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Tab3() {
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      preclick() {
        console.log("loaded map");
        map.locate();
      },
      click() {
        map.locate();
      },
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker position={position} icon={icon}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Coming Soon
      </Typography>
    </Box>
    //   <MapContainer
    //   center={[48.856614, 2.3522219]}
    //    zoom={13}
    //    scrollWheelZoom
    //    >
    //   <TileLayer
    //     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //   />
    //   <LocationMarker />
    // </MapContainer>
  );
}

export default Tab3;
