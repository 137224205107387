import * as React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: "3.5em",
    color:"#ff6d75"
  },
  count: {
    position: "absolute",
    lineHeight: 0,
    color: "#fff",
    top: "1.15em",
    fontSize: "1.5em",
    fontWeight: "bold"
  }
});







function HeartNumber({ size = 10, count = 0 }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ fontSize: size }}>
      <FavoriteIcon className={classes.icon} />
      <Typography component="span" className={classes.count}>
        {count}
      </Typography>
    </div>
  );
}





function mapper(num){
  if(num == 5){
    return '#63E13D'
  }
  else if(num == 4){
    return '#CEFB02'
}
  else if(num == 3){
    return '#FFD700'
}
  else if(num == 2){
    return '#FFA500'
}
  else if(num <= 1){
    return '#CC0202'
}

}


const BorderLinearProgress = styled(LinearProgress)(({ theme, num }) => (
  
{
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: mapper(num),
  },
}));




function LinearProgressWithLabel({ value, num, ...props }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', minWidth: 35 }}>
          
          <HeartNumber count={num} />
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" value={value} num={num} {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }


export default function CustomizedProgress({ value, num, ...props }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <LinearProgressWithLabel value={value || 0} num={num} {...props}/>
    </Box>
  );
}
