import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import {
  OWNER_PHONE,
  OWNER_EMAIL,
  OWNER_FIRST_NAME,
} from "../config/constants";

export default function SpeedDialTooltipOpen(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleClick(e, operation) {
    e.preventDefault();
    console.log("operation: ", operation);
    if (operation === "call") {
      console.log("call");
      window.location.href = `tel:${OWNER_PHONE}`;
    } else if (operation === "text") {
      console.log("text");
      window.location.href = `sms:${OWNER_PHONE};?&body=Hi ${OWNER_FIRST_NAME}%20I'm interested in the ${props.tid}`;
    } else if (operation === "email") {
      console.log("email");
      window.location.href = `mailto:${OWNER_EMAIL}?subject=${props.tid}&body=Hi ${OWNER_FIRST_NAME},%0d%0aI'm interested in the ${props.tid}`;
    } else {
      console.log("No Operation");
    }
    setOpen(!open); // to close the speed dial, remove this line if not needed.
  }
  const actions = [
    {
      icon: <EmailIcon />,
      name: "Email",
      action: handleClick,
      operation: "email",
    },
    {
      icon: <MessageIcon />,
      name: "Text",
      action: handleClick,
      operation: "text",
    },
    {
      icon: <PhoneIcon />,
      name: "Call",
      action: handleClick,
      operation: "call",
    },
  ];
  return (
    <SpeedDial
      ariaLabel="SpeedDial tooltip example"
      sx={props.sx}
      icon={<PhoneIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      onClick={handleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={(e) => {
            handleClick(e, action.operation);
          }}
        />
      ))}
    </SpeedDial>
  );
}
