import { useContext, useState, useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Logout from "./pages/Logout";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import Review from "./pages/Review";
import Box from "@mui/material/Box";
import Sync from "./pages/Sync";
import PersistentDrawerLeft from "./layout/Drawer";
import { UserContext } from "./contexts/UserContext";
import api from "./config/client";
import Listing from "./pages/home/Listing";
import { Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
// import your route components too

// ----------------------------------------------------------------------

export default function Router({ loading }) {
  const { isAuth, su } = useContext(UserContext);

  if (loading)
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return useRoutes([
    {
      path: "/",
      element: <PersistentDrawerLeft />,
      children: [
        { path: "/app", element: <Home /> },
        { path: "/app/home", element: <Navigate replace to="/app" /> },
        { path: "/app/:id", element: <Listing /> },
        { path: "", element: <Navigate replace to="/app" /> },

        {
          path: "/app/login",
          element: !isAuth ? <Login /> : <Navigate to="/" />,
        },
        {
          path: "/app/signup",
          element: !isAuth ? <Signup /> : <Navigate to="/" />,
        },
        { path: "/app/sync", element: su ? <Sync /> : <Navigate to="/" /> },
        { path: "/app/contact", element: <Contact /> },
        { path: "/app/review", element: <Review /> },
        {
          path: "/app/profile",
          element: isAuth ? <Profile /> : <Navigate to="/" />,
        },
        {
          path: "/app/logout",
          element: isAuth ? <Logout /> : <Navigate to="/" />,
        },

        { path: "404", element: <NotFound /> },
        { path: "/app/*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
