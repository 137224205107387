import {Fragment} from 'react';
import LoginForm from '../forms/LoginForm';


const Login = () => {
    return (

      <Fragment>

    <h2>Login</h2>
    <LoginForm />
    </Fragment>

    )
  }


export default Login;