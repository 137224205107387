import { useState, useContext, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StarRateIcon from "@mui/icons-material/StarRate";
import SyncIcon from "@mui/icons-material/Sync";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemLinkRD from "../components/ListItemLinkRD.tsx";
import LogoutIcon from "@mui/icons-material/Logout";
import { DRAWER_WIDTH, APP_TITLE } from "../config/constants";
import { UserContext } from "../contexts/UserContext";
import { ListingContext } from "../contexts/ListingContext";
import { Outlet } from "react-router-dom";
import logo from "../styles/logo.png";
import RealtorChip from "./RealtorChip";

const drawerWidth = DRAWER_WIDTH;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { isAuth, setIsAuth, su, setIsSu } = useContext(UserContext);
  const { loading } = useContext(ListingContext);
  const containerRef = useRef(null);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          open={open}
          style={{
            background: "linear-gradient(to right, #56ccf2, #2f80ed)",
          }}
        >
          
<Toolbar>
  <IconButton
    color="inherit"
    aria-label="open drawer"
    onClick={handleDrawerOpen}
    edge="start"
    sx={{ mr: 2, ...(open && { display: "none" }) }}
  >
    <MenuIcon />
  </IconButton>
  {/* Flex container to ensure it takes full width of the Toolbar */}
  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Box
      sx={{
        height: 40,
        overflow: "hidden",
        display: 'flex', // Make sure this Box also behaves as a flex container
        alignItems: 'center',
      }}
      ref={containerRef}
    >
      <Slide direction="up" in={!loading} timeout={1800}>
        {/* Direct child of Slide should be a single element, wrapping in a div if necessary */}
        <div>
          <Typography variant="h6" noWrap component="div" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <img src={logo} alt="logo" style={{ height: '40px', verticalAlign: 'middle', marginRight: '8px' }} />
            {APP_TITLE}
          </Typography>
        </div>
      </Slide>
    </Box>
    {/* Right side - AvatarChips without interfering with the slide animation */}
    <RealtorChip />
  </Box>
</Toolbar>

        </AppBar>
      </HideOnScroll>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemLinkRD
            icon={<HomeIcon />}
            primary={"Home"}
            to="/app/"
            setOpen={setOpen}
          />
          {!isAuth ? (
            <Fragment>
              <ListItemLinkRD
                icon={<HowToRegIcon />}
                primary={"Login"}
                to="/app/login"
                setOpen={setOpen}
              />
              <ListItemLinkRD
                icon={<PersonAddIcon />}
                primary={"Sign Up"}
                to="/app/signup"
                setOpen={setOpen}
              />
            </Fragment>
          ) : (
            <Fragment>
              {su && (
                <ListItemLinkRD
                  icon={<SyncIcon />}
                  primary={"Sync"}
                  to="/app/sync"
                  setOpen={setOpen}
                />
              )}
              <ListItemLinkRD
                icon={<AccountCircleIcon />}
                primary={"Profile"}
                to="/app/profile"
                setOpen={setOpen}
              />
              <ListItemLinkRD
                icon={<LogoutIcon />}
                primary={"Logout"}
                to="/app/logout"
                setOpen={setOpen}
              />
            </Fragment>
          )}
          <ListItemLinkRD
            icon={<StarRateIcon />}
            primary={"Review Us"}
            to="/app/review"
            setOpen={setOpen}
          />
          <ListItemLinkRD
            icon={<MailIcon />}
            primary={"Contact Us"}
            to="/app/contact"
            setOpen={setOpen}
          />
        </List>
      </Drawer>
      <Main open={open} id="mainDrawerContainer">
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
