import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import sleep from "../config/sleep";
import api from "../config/client";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { Container } from "@mui/material";
import { UserContext } from "../contexts/UserContext";

const Logout = () => {
  const navigate = useNavigate();

  const { isAuth, setIsAuth } = useContext(UserContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log("Logout Use Effect");
    console.log("Token: ", token);
    console.log("Token = Token: ", token == null);
    if (isAuth) return;
    console.log("Token is null");
    navigate("/app/login");
  }, [isAuth]);

  const onSubmit = async (e) => {
    try {
      console.log("Logout Form Values: ", e);
      e.preventDefault();
      setLoading(true);
      let logout = await api.post(
        "/api/auth/logout/",
        JSON.stringify({ key: token })
      );
      console.log("Logout api call: ", logout);
      localStorage.clear();
      setSuccess(true);
      setIsAuth(false);
      enqueueSnackbar("Logged Out", { variant: "success" });
      navigate("/app/login");
    } catch (err) {
      console.log("Logout Error: ", err);
      await sleep(4000);
      enqueueSnackbar("Logout Error", { variant: "error" });
      localStorage.clear();
      navigate(0);
    }
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={onSubmit}>
        <h1>Are you sure you want to logout?</h1>
        <LoadButton loading={loading} success={success} text={"Logout"} />
      </form>
    </Container>
  );
};

export default Logout;
