import { useState, useContext } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import { successVariant } from "../config/client";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";
import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import Zoom from "@mui/material/Zoom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import sleep from "../config/sleep";
import useLocalStorage from "../hooks/useLocalStorage";

function LinearProgressWithLabel(props) {
  console.log("label props: ", props);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const SyncForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [percent, setPercent] = useState(0);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [task_id, setId] = useLocalStorage("task_id", null);

  const iterObject = (obj) => {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      console.log("key2: ", key);
      if (typeof obj[key] === "object") {
        iterObject(obj[key]);
        continue;
      }
      handleNonObjectKeys(obj, key);
    }
  };

  function handleNonObjectKeys(obj, key) {
    if (key === "percent") {
      setPercent(Math.round(obj[key]));
    } else if (key === "total") {
      setTotal(obj[key]);
    } else {
      console.warn("unhandled key: ", key);
    }
  }

  async function fetchData(task_id) {
    console.log("Fetch Data task_id: ", task_id);
    const { data, status } = await api.get(`/api/get_progress/${task_id}/`);
    if (status !== 200 || !data) return;
    console.log("data from fetch data: ", data);
    const state = data.state;

    if (state === "PROGRESS") {
      console.log("In Progress");
      iterObject(data);
    } else if (state === "PENDING") {
      console.log("Starting Up");
      setPending(true);
    } else if (state === "SUCCESS") {
      setSuccess(true);
      enqueueSnackbar("Sync successfully completed", successVariant);
      return true;
    }
    return false;
  }

  const onSubmit = async (values) => {
    try {
      console.log("values: ", values);
      let token = localStorage.getItem("token");
      api.defaults.headers["Authorization"] = token ? `Token ${token}` : null;
      console.log('token:', token)
      const { data, status } = await api.post(`/api/start_syncing/`);
      if (status !== 202) return;
      enqueueSnackbar("Sync Started", { variant: "success" });
      setLoading(true);
      const { task_id } = data;
      setId(task_id);

      let finished = false;

      while (!finished) {
        finished = await fetchData(task_id);
        await sleep(2000);
      }

      // await setTimeout(async () => {
      //   await sleep(50000);
      // });
    } catch (err) {
      setError(err.toString());
      console.error("Sync Start Error: ", err);
      // clearInterval(axiosInterval);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  const formik = useFormik({
    initialValues: { task_id: "" },
    onSubmit,
  });

  return (
    <Container maxWidth="sm">
      <form onSubmit={formik.handleSubmit}>
        <LoadButton loading={loading} success={success} text="Start Sync" />
        <Fade in={loading && !success}>
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={percent} />
          </Box>
        </Fade>

        {success ? (
          <Box sx={{ display: "flex", alignItems: "center", color: "green" }}>
            <Typography>All Synced with {total} listings </Typography>
            <SentimentVerySatisfiedIcon />
          </Box>
        ) : null}
      </form>
    </Container>
  );
};

export default SyncForm;
