import { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";

import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";
import useLocalStorage from "../hooks/useLocalStorage";
import { TrackLogin } from "../config/ga";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const initialValues = (props) => {
  return {
    email: "",
    password: "",
  };
};

const LoginForm = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    isAuth,
    setIsAuth,
    su,
    setIsSu,
    profile,
    setProfile,
    p_loading,
    setPLoading,
  } = useContext(UserContext);
  const [success, setSuccess] = useState(false);

  const fetchData = async () => {
    try {
      const { data, status } = await api.get("/api/user_endpoint/");
      if (status !== 200) throw new Error("Error fetching user");
      setProfile(data);
      setPLoading(false);
      console.log("data: ", data);
      if (!data.user.is_superuser) return;
      setIsSu(true);
      navigate("/app/sync");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      console.log("Login Form Values: ", values);
      setPLoading(true);
      localStorage.clear();
      api.defaults.headers["Authorization"] = null;
      let res = await api.post("/api/auth/login/", JSON.stringify(values));
      let token = res.data.key;
      localStorage.setItem("token", token);
      api.defaults.headers["Authorization"] = token ? `Token ${token}` : null;

      setIsAuth(true);
      setSuccess(true);
      TrackLogin();
      enqueueSnackbar("Logged in", { variant: "success" });
      await fetchData();
    } catch (err) {
      localStorage.clear();

      console.error("Login Error: ", err);
      enqueueSnackbar("Error", { variant: "error" });
      setIsAuth(false);
      setSuccess(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={initialValues(props)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(formik) => (
          <Form>
            <TextField
              fullWidth
              {...formik.getFieldProps("email")}
              id="email"
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              {...formik.getFieldProps("password")}
              id="password"
              label="Password"
              type="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            {formik.isValid && (
              <LoadButton loading={p_loading} success={success} text="Login" />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LoginForm;
