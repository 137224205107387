import { Fragment } from "react";

import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

import Zoom from "@mui/material/Zoom";

function LoadButton({ loading, success, text }) {
  return (
    <Fragment>
      <Zoom in={!loading || !success} mountOnEnter unmountOnExit>
        <LoadingButton
          color={"primary"}
          loading={loading}
          variant="contained"
          fullWidth
          type="submit"
          endIcon={<SendIcon />}
        >
          {text}
        </LoadingButton>
      </Zoom>
    </Fragment>
  );
}

export default LoadButton;
