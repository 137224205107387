import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2f80ed',
      backdropFilter: 'blur(20px)',
      borderStyle: 'solid',
      borderColor: '#E7EBF0',
      borderWidth: 0,
      borderBottomWidth: 'thin',
      background: 'rgb(0,255,226)',
      background: 'linear-gradient(90deg, rgba(0,255,226,1) 0%, rgba(0,212,255,1) 43%, rgba(33,138,247,1) 100%)',
      color: '#2D3843',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    success: {
      main:'#90EE90',
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          marginTop: '0.5rem',
          marginBottom: '0.5rem',

        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

export default theme;
