import { useState, useContext, forwardRef } from "react";
import { Formik, Form } from "formik";

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";
import { OutlinedInput } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL, RECAPTCHA_SITE_KEY } from "../config/constants";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone_number: yup
    .string()
    .min(14, "Phone Number must be 10 digits")
    .required("Phone Number is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  message: yup
    .string("Enter your password")
    .max(5000, "5000 characters max")
    .required("Password is required"),
});

const initialValues = (props) => {
  console.log("Initial Values Props: ", props);
  return {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    message: "",
  };
};

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ContactForm = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [captchaResult, setCaptchaResult] = useState();

  const handleRecaptcha = async (value) => {
    console.log("Captcha Value: ", value);
    let res = await api.post(
      "/api/recaptcha/",
      JSON.stringify({ captcha_value: value })
    );
    console.log("Captcha Response: ", res);
    console.log(res.data.captcha.success);
    setCaptchaResult(res.data.captcha.success);
  };

  const onSubmit = async (values) => {
    try {
      console.log("Contact Form Values: ", values);

      setLoading(true);
      await sleep(1500);

      let res = await api.post("/api/leads_endpoint/", JSON.stringify(values));
      console.log("Contact Response Status:", res.status);
      console.log("Contact Response: ", res);
      setSuccess(true);
      await sleep(600);
      navigate("/app/home");
      enqueueSnackbar("Message Sent, we will get back to you shortly.", {
        variant: "success",
      });
    } catch (err) {
      console.log("Contact Error: ", err);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={initialValues(props)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <TextField
              fullWidth
              id="first_name"
              label="First name"
              {...formik.getFieldProps("first_name")}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
            <TextField
              fullWidth
              id="last_name"
              label="Last name"
              {...formik.getFieldProps("last_name")}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel
                sx={{ display: "flex", alignSelf: "center", ml: 2 }}
                htmlFor="phone_number"
              >
                Phone Number
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="phone_number"
                {...formik.getFieldProps("phone_number")}
                inputComponent={TextMaskCustom}
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
              />
              <FormHelperText error id="accountId-error">
                {formik.touched.phone_number && formik.errors.phone_number}
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              id="email"
              label="Email"
              {...formik.getFieldProps("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              multiline
              id="message"
              label="Message"
              {...formik.getFieldProps("message")}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
            {captchaResult && (
              <LoadButton
                loading={loading}
                success={success}
                text="Send"
                onClick={(e) => {
                  onSubmit(e);
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactForm;
