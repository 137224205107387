import { useState, useEffect, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ImageCard from "../../components/ImageCard";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { ListingContext } from "../../contexts/ListingContext";

const Tab2 = () => {
  const [isDetail, setIsDetail] = useState(false);
  const { listings, setListings, loading, cities } = useContext(ListingContext);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const params = useParams();
  let paramsKey = Object.keys(params);
  let pkLength = paramsKey.length;

  useEffect(() => {
    const checkpk = () => {
      if (pkLength != 0) {
        setIsDetail(true);
      } else {
        setIsDetail(false);
      }
    };

    checkpk();
  }, [pkLength]);

  return (
    <Container maxWidth="md" id="imageCardContainer" sx={{ p: 0 }}>
      <Outlet />

      <Box pb={5} mb={5}>
        <Typography variant="h6">
          {loading ? <CircularProgress /> : listings.length + " Listings"}
        </Typography>
        {listings.map((l, i) => {
          return <ImageCard setIsDetail={setIsDetail} l={l} key={i} />;
        })}
      </Box>
    </Container>
  );
};

export default Tab2;
