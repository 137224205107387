import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const marks = [
  {
    value: 2000,
    label: "$2,000",
  },

  {
    value: 4000,
    label: "$4,000",
  },

  {
    value: 6000,
    label: "$6,000",
  },
  {
    value: 8000,
    label: "$8,000",
  },
  {
    value: 10000,
    label: "$10,000",
  },
];

const validationSchema = yup.object({
  city: yup.string(),
  rent: yup.number(),
  beds: yup.array(),
  sqft: yup.number(),
  den: yup.boolean(),
  loft: yup.boolean(),
  family_room: yup.boolean(),
  in_unit_laundry: yup.boolean(),
});

const initialValues = (props) => {
  return {
    city: "",
    rent: 10000,
    beds: "",
    sqft: 0,
    den: false,
    loft: false,
    family_room: false,
    in_unit_laundry: false,
  };
};

function valuetext(value) {
  return `$${value}`;
}

function sqfttext(value) {
  return `${value} sqft`;
}

const ListingForm = (props) => {
  const { cities, setListings, handleClose } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);
  const [beds, setBeds] = useState(null);
  const [rent, setRent] = useState(10000);
  const [sqft, setSqft] = useState(0);
  const [state, setState] = useState({
    den: false,
    loft: false,
    family_room: false,
    in_unit_laundry: false,
  });

  const onSubmit = async (values) => {
    try {
      console.log("Listing Form Values: ", values);

      setLoading(true);
      await sleep(1500);

      let res = await api.post("/api/listings/", JSON.stringify(values));
      console.log("Listing Response Status:", res.status);
      console.log("Listing Response: ", res);
      setSuccess(true);
      setListings(res.data);
      enqueueSnackbar(`Search resulted in ${res.data.length} listings`, {
        variant: "success",
      });
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log("Listing search Error: ", err);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  const formik = useFormik({
    initialValues: initialValues(props),
    validationSchema,
    onSubmit,
  });

  const handleSwitchChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleBedsChange = (event, newBeds) => {
    setBeds(newBeds);
    formik.setFieldValue("beds", newBeds);
  };
  const handleRentChange = (event, newRent) => {
    setRent(newRent);
    formik.setFieldValue("rent", newRent);
  };

  const handleSqftChange = (event, newSqft) => {
    setSqft(newSqft);
    formik.setFieldValue("sqft", newSqft);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            id="city"
            options={cities}
            onChange={(event, newValue) => {
              console.log("Event: ", event, "New Value: ", newValue);
              formik.setFieldValue("city", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...formik.getFieldProps("city")}
                label="City"
              />
            )}
          />
          <Divider
            style={{
              position: "relative",
              width: "100%",
              height: 5,
            }}
          />
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>{"< $ Rent"}</Typography>

            <Slider
              aria-label="Rent"
              defaultValue={10000}
              getAriaValueText={valuetext}
              onChange={handleRentChange}
              step={100}
              marks={marks}
              min={1000}
              max={10000}
              valueLabelDisplay="on"
            />
          </Box>
          <Divider
            style={{
              position: "relative",
              width: "100%",
              height: 5,
            }}
          />
          <Typography sx={{ textAlign: "center" }}>Beds</Typography>

          <Box display="flex" justifyContent="center">
            <ToggleButtonGroup
              color="primary"
              value={beds}
              onChange={handleBedsChange}
              id="beds"
              sx={{ width: "100%" }}
            >
              <ToggleButton sx={{ width: "20%" }} value={0}>
                0
              </ToggleButton>
              <ToggleButton sx={{ width: "20%" }} value={1}>
                1
              </ToggleButton>
              <ToggleButton sx={{ width: "20%" }} value={2}>
                2
              </ToggleButton>
              <ToggleButton sx={{ width: "20%" }} value={3}>
                3
              </ToggleButton>
              <ToggleButton sx={{ width: "20%" }} value={4}>
                4
              </ToggleButton>
              <ToggleButton sx={{ width: "20%" }} value={5}>
                5
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Divider
            style={{
              position: "relative",
              width: "100%",
              height: 5,
            }}
          />
          <Typography sx={{ textAlign: "center" }}>{"> sqft."}</Typography>
          <Box width={"100%"}>
            <Slider
              aria-label="Sqft"
              defaultValue={0}
              getAriaValueText={sqfttext}
              onChange={handleSqftChange}
              step={100}
              min={0}
              max={4000}
              valueLabelDisplay="on"
            />
          </Box>
          <Divider
            style={{
              position: "relative",
              width: "100%",
              height: 5,
            }}
          />
          <Box width={"100%"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.den}
                    onChange={handleSwitchChange}
                    name="den"
                  />
                }
                label="Den"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.loft}
                    onChange={handleSwitchChange}
                    name="loft"
                  />
                }
                label="Loft"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.family_room}
                    onChange={handleSwitchChange}
                    name="family_room"
                  />
                }
                label="Family Room"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.in_unit_laundry}
                    onChange={handleSwitchChange}
                    name="in_unit_laundry"
                  />
                }
                label="In-unit Laundry"
              />
            </FormGroup>
          </Box>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItem="flex-end"
          >
            <LoadingButton
              endIcon={<SearchIcon />}
              loading={loading}
              loadingPosition="end"
              variant="outlined"
              type="submit"
            >
              Search
            </LoadingButton>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default ListingForm;
