import { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";
import { APP_TITLE } from "../config/constants";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomizedProgress from "../components/CustomizedProgress";
import { Button } from "@mui/material";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  rating: yup
    .number()
    .min(0, "Rating must be at least one")
    .max(5, "Maximum rating is 5 stars")
    .typeError("Must be a number")
    .required("Rate us honestly, we can handle it")
    .test("maxLength", "Max 5", (val) => !isNaN(val) && `${val}`.length <= 5),
  opinion: yup
    .string("Enter your opinion")
    .max(5000, "5000 characters max")
    .required("Opinion is required"),
});

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const initialValues = (props) => {
  console.log("Initial Values Props: ", props);
  return {
    rating: null,
    first_name: "",
    opinion: "",
  };
};

function getLabelText(value) {
  return `${value} Heart${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function getLocalDate(date) {
  const datepf = new Date(date);

  // 👇️ "Sat Jan 15 2022 13:02:17 GMT+0200 (Eastern European Standard Time)"

  // ✅ Convert to Local time
  return datepf.toLocaleString();
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const ReviewForm = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isAuth, setIsAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reviewsLoading, setreviewsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [reviewSummary, setReviewSummary] = useState({});
  const [ratingValue, setRatingValue] = useState(0);
  const [hover, setHover] = useState(-1);
  const [ave, setAve] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const loadReviews = async () => {
      try {
        let res = await api.get("/api/reviews/");

        if (res.status === 200) {
          await sleep(600);
          console.log("Reviews Get Response: ", res);
          enqueueSnackbar("Reviews Loaded", { variant: "success" });
          setReviews(res.data);
          let count = {};
          let array_of_values = [];
          for (let i = 0; i < res.data.length; i++) {
            array_of_values.push(res.data[i].rating);
          }
          console.log("array of review ratings: ", array_of_values);

          let array_length = array_of_values.length;

          console.log("Pre Summary: ", count);

          for (let i = 0; i < array_length; i++) {
            if (count[array_of_values[i]]) {
              count[array_of_values[i]] += 1;
            } else {
              count[array_of_values[i]] = 1;
            }
          }

          console.log("Post Summary: ", count);
          let final_sum = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
          };

          for (let key in count) {
            if (key == "0.50") {
              final_sum[1] += count[key];
            } else if (key == "1.00") {
              final_sum[1] += count[key];
            } else if (key == "1.50") {
              final_sum[2] += count[key];
            } else if (key == "2.00") {
              final_sum[2] += count[key];
            } else if (key == "2.50") {
              final_sum[3] += count[key];
            } else if (key == "3.00") {
              final_sum[3] += count[key];
            } else if (key == "3.50") {
              final_sum[4] += count[key];
            } else if (key == "4.00") {
              final_sum[4] += count[key];
            } else if (key == "4.50") {
              final_sum[5] += count[key];
            } else if (key == "5.00") {
              final_sum[5] += count[key];
            } else {
              console.log("Error with key count final sum: ", key);
            }
          }

          let sum = array_of_values
            .map(Number)
            .reduce((total, amount) => total + amount, 0);
          console.log("Sum: ", typeof sum, sum);
          let average = sum / array_length;
          setAve(average.toFixed(2));

          console.log("Post Final Summary: ", final_sum);

          for (let key in final_sum) {
            final_sum[key] = (final_sum[key] / array_length) * 100;
          }

          setReviewSummary(final_sum);
          setreviewsLoading(false);
        }
      } catch (err) {
        console.log("Reviews Get Error: ", err);
        enqueueSnackbar("Error", { variant: "error" });
      }
    };
    loadReviews();
  }, []);

  const onSubmit = async (values) => {
    console.log("Review Post Values: ", values);
    validationSchema
      .validate(values, { abortEarly: false })
      .then(function () {
        // Success
      })
      .catch(function (err) {
        err.inner.forEach((e) => {
          console.log(e.message, e.path);
        });
      });

    try {
      console.log("Review POST Form Values: ", values);

      setLoading(true);
      await sleep(1500);

      let res = await api.post("/api/reviews/", JSON.stringify(values));
      console.log("Review POST Response Status:", res.status);
      console.log("Review POST Response: ", res);
      setSuccess(true);
      await sleep(600);
      enqueueSnackbar(`Thank you for reviewing ${APP_TITLE}`, {
        variant: "success",
      });
      await sleep(1000);
      navigate(0);
    } catch (err) {
      console.log("Review POST Error: ", err);
      enqueueSnackbar("Error", { variant: "error" });
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                width: 200,
                display: "flex",
              }}
            >
              <FormControl>
                <FormLabel>Rating out of 5</FormLabel>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <StyledRating
                    sx={{ fontSize: 45 }}
                    id="rating"
                    label="Rating"
                    {...formik.getFieldProps("rating")}
                    onChange={(event, newValue) => {
                      setRatingValue(newValue);
                      formik.setFieldValue("rating", newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    getLabelText={getLabelText}
                    precision={0.5}
                    icon={<FavoriteIcon fontSize="inherit" />}
                    emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                  />

                  {ratingValue !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : ratingValue]}
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
            {console.log(formik.errors, formik.touched)}
            {formik.errors.rating ? (
              <Typography sx={{ color: "red" }}>
                {formik.errors.rating}
              </Typography>
            ) : null}
            <TextField
              fullWidth
              id="first_name"
              label="First name"
              value={formik.values.first_name}
              {...formik.getFieldProps("first_name")}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />

            <TextField
              fullWidth
              multiline
              rows={3}
              id="opinion"
              label="Opinion"
              value={formik.values.opinion}
              {...formik.getFieldProps("opinion")}
              error={formik.touched.opinion && Boolean(formik.errors.opinion)}
              helperText={formik.touched.opinion && formik.errors.opinion}
            />

            {formik.isValid && (
              <LoadButton
                loading={loading}
                success={success}
                disabled={formik.isSubmitting || !formik.isValid}
                text="Create Review"
              />
            )}
          </Form>
        )}
      </Formik>

      <Divider sx={{ mt: 4, mb: 4 }} />

      <Card>
        <CardContent>
          {!reviewsLoading ? (
            <Box>
              <Typography variant="h6" color="text.secondary">
                {APP_TITLE} Reviews
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  fontWeight: "bold",
                  alignItems: "baseline",
                }}
              >
                <Typography
                  variant="h2"
                  style={{ fontWeight: "bold" }}
                  color="text.secondary"
                >
                  {" "}
                  {(Math.round(ave * 4) / 4).toFixed(2)}
                </Typography>
                <StyledRating
                  value={(Math.round(ave * 4) / 4).toFixed(2)}
                  icon={<FavoriteIcon fontSize="inherit" />}
                  emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                  readOnly
                  precision={0.1}
                />
              </Box>

              {Object.keys(reviewSummary)
                .reverse()
                .map((value, i) => {
                  return (
                    <CustomizedProgress
                      value={reviewSummary[value]}
                      num={5 - i}
                      key={i}
                    />
                  );
                })}
            </Box>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress />

              <Typography paragraph> Loading </Typography>
            </Stack>
          )}
        </CardContent>

        {!reviewsLoading ? (
          <CardActions disableSpacing>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
        ) : null}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {!reviewsLoading
              ? reviews.map((value, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          fontWeight: "bold",
                          alignItems: "center",
                          height: 140,
                          lineHeight: 0,
                        }}
                      >
                        <StyledRating
                          value={value.rating}
                          icon={<FavoriteIcon fontSize="inherit" />}
                          emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                          readOnly
                          precision={0.5}
                        />
                        <Typography sx={{ lineHeight: 0 }} paragraph>
                          <blockquote>
                            &ldquo;{value.opinion}.&rdquo;
                          </blockquote>
                        </Typography>
                        <Typography>
                          {" "}
                          - <i>{value.first_name}</i>{" "}
                        </Typography>
                        <Typography>
                          {" "}
                          - <i>{getLocalDate(value.created_at)}</i>{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Divider style={{ width: "100%" }} />
                      </Box>
                    </Box>
                  );
                })
              : null}
          </CardContent>
        </Collapse>
      </Card>
    </Container>
  );
};

export default ReviewForm;
