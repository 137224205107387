import React, { Fragment } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import ReviewForm from "../forms/ReviewForm";

function Review() {
  return (
    <Container>
      <Box mb={5} pb={5}>
        <h2>Reviews</h2>
        <ReviewForm />
      </Box>
    </Container>
  );
}

export default Review;
