import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  } from '@mui/material';

function ListItemLinkRD(props) {
  const { icon, primary, to, setOpen} = props;

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(function Linker(
        linkProps,
        ref,
      ) {
        return <Link ref={ref} to={to} {...linkProps} />;
      }),
    [to],
  );

  return (
    <li>
      <ListItemButton component={CustomLink} onClick={() => setOpen(false)} >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary}  />
      </ListItemButton>
    </li>
  );
}

export default ListItemLinkRD;