import {Fragment} from 'react';
import ContactForm from '../forms/ContactForm';


function Contact() {
    return (

      <Fragment>

    <h2>Contact Us</h2>
    <ContactForm />
    </Fragment>

    )
  }


export default Contact;