import { useContext, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ListingContext } from "../contexts/ListingContext";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { green, blue } from "@mui/material/colors";

import Carousel from "./Carousel";
import Grid from "@mui/material/Grid";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { alpha } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

import Card from "@mui/material/Card";

import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import "../styles/listing.css";
import PetsComponent from "./Pets";
import Features from "./Features";
import SpeedDialTooltipOpen from "./SpeedDial";
// import Chat from "./Chat";
import api from "../config/client";
import { BASE_URL } from "../config/constants";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light" ? "white" : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const MyGallery = ({ img_list }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card sx={{ minWidth: { xs: 345 } }} elevation={0}>
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Gallery
            sx={{
              display: "flex",
              justifyContent: "center",
              direction: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                direction: "row",
              }}
            >
              <div style={{ margin: "0 auto" }}>
                {img_list.map((i) => (
                  <Item
                    original={i.original}
                    thumbnail={i.thumb}
                    width="1024"
                    height="768"
                    key={uuidv4()}
                  >
                    {({ ref, open }) => (
                      <img
                        key={uuidv4()}
                        ref={ref}
                        onClick={open}
                        src={i.thumb}
                        width="50px"
                      />
                    )}
                  </Item>
                ))}
              </div>
            </Box>
          </Gallery>
        </Grid>
      </div>
    </Card>
  );
};

const fabBlueStyle = {
  position: "fixed",
  bottom: 76,
  right: 16,
  color: "common.white",
};

const ListingComponent = ({
  li,
  toggleDrawer,
  handleChange,
  textIdentifier,
  isOpen,
  expanded,
}) => {
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />

      <Grid container sx={{ height: "auto", display: "flex", flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "white",
            height: { md: "90vh" },
          }}
        >
          <Carousel images={li.images} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
            flexDirection: "row",
            alignItems: "center",
          }}
          item
          xs={12}
          md={6}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">${li.rent}</Typography>
            <Typography variant="h6">{li.city}</Typography>

            <Box
              sx={{
                mt: 1.5,
                p: 0.5,
                textAlign: "center",
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                borderRadius: "5px",
                color: "primary.main",
                fontWeight: "medium",
                fontSize: 12,
                alignItems: "center",
                "& svg": {
                  fontSize: 21,
                  mr: 0.5,
                },
              }}
            >
              <Typography paragraph>
                <BedOutlinedIcon style={{ verticalAlign: "middle" }} />
                <b>{li.beds}</b> bd |{" "}
                <b>
                  {" "}
                  {li.baths == "1.00" && "1"}
                  {li.baths == "1.50" && "1½"}
                  {li.baths == "1.75" && "1¾"}
                  {li.baths == "2.00" && "2"}
                  {li.baths == "2.50" && "2½"}
                  {li.baths == "2.75" && "2¾"}
                  {li.baths == "3.00" && "3"}
                  {li.baths == "3.50" && "3½"}
                  {li.baths == "3.75" && "3¾"}
                  {li.baths == "4.00" && "4"}
                  {li.baths == "4.50" && "4½"}
                  {li.baths == "4.75" && "4¾"}
                  {li.baths == "5.00" && "5"}
                  {li.baths == "5.50" && "5½"}
                  {li.baths == "5.75" && "5¾"}
                </b>{" "}
                ba <BathtubOutlinedIcon style={{ verticalAlign: "top" }} />
              </Typography>
              <Typography paragraph>
                <SquareFootIcon style={{ verticalAlign: "top" }} />
                <b>{li.sqft}</b> sqft.
              </Typography>
              <PetsComponent li={li} />
            </Box>

            {/* <Chat id={li.padleads_id} />*/}
            <Button onClick={toggleDrawer(true)}>View More</Button>

            <SpeedDialTooltipOpen tid={textIdentifier} sx={fabBlueStyle} />
          </Box>
        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            All Details
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            pt: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            key={1}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                variant="h6"
                sx={{ width: "100%", textAlign: "center" }}
              >
                Description & {li.images.length} Images
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={3}>
              <Typography>{li.description}</Typography>
              <MyGallery img_list={li.images} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            key={2}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                variant="h6"
                sx={{ width: "100%", textAlign: "center" }}
              >
                Features
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={4}>
              <Features
                features={Object.entries(li.features).filter(
                  (x) => x[1].length > 0
                )}
              />
            </AccordionDetails>
          </Accordion>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export default ListingComponent;
