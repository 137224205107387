import { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import api from "../config/client";
import sleep from "../config/sleep";
import { useSnackbar } from "notistack";
import LoadButton from "../components/LoadButton";
import { UserContext } from "../contexts/UserContext";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password1: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup.string().oneOf([yup.ref("password1")], "Passwords must match"),
});

const initialValues = (props) => {
  console.log("Initial Values Props: ", props);
  return {
    email: "",
    password1: "",
    password2: "",
  };
};

const SignupForm = (props) => {
  const { isAuth, setIsAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      console.log("Sign Up Form Values: ", values);
      setLoading(true);
      console.log("SignupForm onSubmit: ", values);
      api.defaults.headers["Authorization"] = null;
      let res = await api.post(
        "/api/auth/registration/",
        JSON.stringify(values)
      );
      let token = res.data.key;

      api.defaults.headers["Authorization"] = token ? `Token ${token}` : null;

      console.log("Response Status:", res.status);
      console.log("Sign Up Success: ", res);
      console.log("Received Signup Token: ", token);

      localStorage.setItem("token", token);
      setIsAuth(true);
      setSuccess(true);
      navigate("/app/home");
      enqueueSnackbar("Signed Up Succesfully", { variant: "success" });
    } catch (err) {
      console.error(err);
      let er = err.response;
      console.log("error response: ", er);
      setLoading(false);
      setSuccess(false);
      console.log("Sign Up Error: ", er.data);
      if ("non_field_errors" in er.data) {
        enqueueSnackbar(`Error: ${er.data.non_field_errors[0]}`, {
          variant: "error",
        });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={initialValues(props)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {(formik) => (
          <Form>
            <TextField
              fullWidth
              {...formik.getFieldProps("email")}
              id="email"
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              {...formik.getFieldProps("password1")}
              id="password1"
              label="Password"
              type="password"
              error={
                formik.touched.password1 && Boolean(formik.errors.password1)
              }
              helperText={formik.touched.password1 && formik.errors.password1}
            />
            <TextField
              fullWidth
              {...formik.getFieldProps("password2")}
              id="password2"
              label="Repeat Password"
              type="password"
              error={
                formik.touched.password2 && Boolean(formik.errors.password2)
              }
              helperText={formik.touched.password2 && formik.errors.password2}
            />
            {formik.isValid && (
              <LoadButton
                loading={loading}
                success={success}
                disabled={formik.isSubmitting || !formik.isValid}
                text="Sign Up"
              />
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SignupForm;
