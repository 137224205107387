import { useContext, useEffect, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { ListingContext } from "../../contexts/ListingContext";
import ListingComponent from "../../components/ListingComponent";
import api from "../../config/client";
import { BASE_URL } from "../../config/constants";
import CircularProgress from "@mui/material/CircularProgress";

const Listing = (props) => {
  const [isDetail, setIsDetail] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();

  const { listings } = useContext(ListingContext);
  const getListingFromContext = () => {
    if (!listings) return null;
    return listings.find((l) => {
      return l.padleads_id === Number(id);
    });
  };
  const [listing, setListing] = useState(getListingFromContext());

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  const params = useParams();
  const paramsKey = Object.keys(params);
  const pkLength = paramsKey.length;
  const checkpk = () => {
    if (pkLength != 0) {
      setIsDetail(true);
      return;
    }
    setIsDetail(false);
  };

  useEffect(() => {
    console.log({ listing });
    if (listing) return;

    loadListings(id);
  }, []);

  useEffect(() => {
    checkpk();
  }, [pkLength]);

  const loadListings = async (id) => {
    try {
      let res = await api.get(BASE_URL + "/api/listing/id/" + id);

      if (res.status !== 200) return <div>ID Not Found or Error...</div>;
      setListing(res.data);

      // console.log("Res: ", res);
      // const li = res.data;

      // console.log("New Li: ", li);
      // return (
      //   <ListingComponent
      //     li={li}
      //     toggleDrawer={toggleDrawer}
      //     handleChange={handleChange}
      //     setTextIdentifier={setTextIdentifier}
      //     isOpen={isOpen}
      //     expanded={expanded}
      //   />
      // );
    } catch (e) {
      console.log("Error in listing get: ", e);
    }
  };

  console.log({ listing });
  if (!listing) {
    return <CircularProgress />;
  }
  const textIdentifier = `${listing.beds} beds and ${listing.baths} baths for $${listing.rent} in ${listing.city}`;

  return (
    <ListingComponent
      li={listing}
      toggleDrawer={toggleDrawer}
      handleChange={handleChange}
      textIdentifier={textIdentifier}
      isOpen={isOpen}
      expanded={expanded}
    />
  );
};

export default Listing;
