import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PetsIcon from "@mui/icons-material/Pets";

const PetsComponent = ({ li }) => {
  const [dogs, setDogs] = useState(false);
  const [cats, setCats] = useState(false);

  useEffect(() => {
    console.log("li in pets component : ", li);
    const checkLi = () => {
      for (let key in li) {
        if (key == "features") {
          for (let subkey in li[key]) {
            if (subkey == "pet_policy") {
              console.log("pet policy exists");
              console.log("Pet policy : ", li[key][subkey]);
              for (const value of li[key][subkey]) {
                if (value.includes("Dogs Allowed")) {
                  setDogs(true);
                }
                if (value.includes("Cats Allowed")) {
                  setCats(true);
                }
              }
            }
          }
        }
      }
    };
    checkLi();
  }, []);

  const pets = () => {
    if (dogs && cats) {
      return (
        <Typography paragraph>
          <PetsIcon style={{ verticalAlign: "top" }} />
          <b>Pets Allowed</b>
        </Typography>
      );
    } else if (dogs && !cats) {
      return (
        <Typography paragraph>
          <PetsIcon style={{ verticalAlign: "top" }} />
          <b>Dogs Allowed</b>
        </Typography>
      );
    } else if (!dogs && cats) {
      return (
        <Typography paragraph>
          <PetsIcon style={{ verticalAlign: "top" }} />
          <b>Cats Allowed</b>
        </Typography>
      );
    }
  };
  return <Box>{pets()}</Box>;
};

export default PetsComponent;
