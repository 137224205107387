import React, {Fragment} from 'react';
import SignupForm from '../forms/SignUpForm';



function Signup() {
    return (
    <Fragment>
    <h2>Sign Up</h2>
    <SignupForm />
  </Fragment>
    ) 
  }


export default Signup;