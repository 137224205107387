import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import useMediaQuery from '@mui/material/useMediaQuery';

// Style your tooltip here
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    fontSize: '0.875rem',
    whiteSpace: 'nowrap', // Ensures the content is on a single line
  },
});

// Assuming the phone number is defined
const OWNER_PHONE = "+18185778499";
const PHONE_DISPLAY = "(818) 577 - 8499";
const REALTY_INFO = "Call Steve";

export default function RealtorChip() {
  const [realtorTooltipOpen, setRealtorTooltipOpen] = React.useState(false);
  const isDesktop = useMediaQuery('(min-width:600px)');

  const toggleRealtorTooltip = () => {
    // Only toggle tooltip on mobile
    if (!isDesktop) {
      setRealtorTooltipOpen(!realtorTooltipOpen);
    }
  };

  const handleCall = () => {
    // Trigger a phone call
    window.location.href = `tel:${OWNER_PHONE}`;
  };

  return (
    <ClickAwayListener onClickAway={() => setRealtorTooltipOpen(false)}>
      <Stack direction="row" spacing={1} style={{ marginLeft: 5 }}>
        {/* Call Chip */}
        <CustomTooltip
          title={<div>{REALTY_INFO} from Angels Realty<br />{PHONE_DISPLAY}</div>}
        >
          <Chip
            icon={<LocalPhoneIcon style={{ color: 'white' }} />}
            label="Call"
            variant="outlined"
            onClick={handleCall}
            style={{ cursor: 'pointer', color: 'white', borderColor: 'transparent' }}
          />
        </CustomTooltip>

        {/* Realtor Chip with Conditional Tooltip */}
        {isDesktop ? (
          <CustomTooltip title={<div>Steven Frischer<br /><a style={{ color: 'skyblue' }} href="https://www2.dre.ca.gov/PublicASP/pplinfo.asp?License_id=01868104" target="_blank">DRE # 01868104</a></div>}>
            <Chip
              variant="outlined"
              style={{ color: 'white' }}
              avatar={<Avatar style={{ color: 'black', background: 'white' }}>SF</Avatar>}
              label="Realtor®"
            />
          </CustomTooltip>
        ) : (
          <div onClick={toggleRealtorTooltip}>
            <CustomTooltip
              title={<div>Steven Frischer<br /><a style={{ color: 'skyblue' }} href="https://www2.dre.ca.gov/PublicASP/pplinfo.asp?License_id=01868104" target="_blank">DRE # 01868104</a></div>}
              open={realtorTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Chip
                variant="outlined"
                style={{ color: 'white' }}
                avatar={<Avatar style={{ color: 'black', background: 'white' }}>SF</Avatar>}
                label="Realtor®"
              />
            </CustomTooltip>
          </div>
        )}
      </Stack>
    </ClickAwayListener>
  );
}
